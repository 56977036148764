<div class="content">

  <div *ngIf="success" class="bloc-success">
    <div class="alert alert-success">
      <strong>Compte enregistré !</strong> Merci vous pouvez jouer et enregistrer vos scores
    </div>

    <a [routerLink]="'/'" class="btn">Retour à l'accueil</a>
  </div>


  <form (ngSubmit)="register()" *ngIf="!success" [formGroup]="registerForm">
    <h1 class="logo">
      <img loading="lazy" alt="Logo" src="assets/images/logo.svg"/>
      <div class="title">BLINEE <img loading="lazy" src="/assets/images/beta.svg" alt="Beta blinee"></div>
    </h1>

    <div *ngIf="error" class="alert alert-danger">
      <strong>Compte non créé !</strong> Merci d'essayer à nouveau plus tard.
    </div>

    <div *ngIf="errorUserExists" class="alert alert-danger">
      <strong>Ce compte utilisateur existe déjà !</strong> Veuillez en choisir un autre.
    </div>

    <div *ngIf="errorEmailExists" class="alert alert-danger">
      <strong>Cet email est déjà utilisé !</strong> Veuillez en choisir un autre.
    </div>

    <div *ngIf="doNotMatch" class="alert alert-danger">
      Le nouveau mot de passe et sa confirmation ne sont pas égaux !
    </div>

    <div class="form-group">
      <label>Email</label>
      <input formControlName="email" placeholder="Votre adresse mail" type="text"/>
      <div
        *ngIf="registerForm.get('email')!.invalid && (registerForm.get('email')!.dirty || registerForm.get('email')!.touched)">
        <small
          *ngIf="registerForm.get('email')?.errors?.required"
          class="form-text-alert"
        >
          Votre email est requis.
        </small>

        <small
          *ngIf="registerForm.get('email')?.errors?.invalid"
          class="form-text-alert"
        >
          Votre email n'est pas valide.
        </small>

        <small
          *ngIf="registerForm.get('email')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre email doit comporter au moins 5 caractères.
        </small>

        <small
          *ngIf="registerForm.get('email')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre email ne doit pas comporter plus de 50 caractères.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Nom d'utilisateur</label>
      <input formControlName="login" placeholder="Votre nom d'utilisateur" type="text"/>

      <div
        *ngIf="registerForm.get('login')!.invalid && (registerForm.get('login')!.dirty || registerForm.get('login')!.touched)">
        <small
          *ngIf="registerForm.get('login')?.errors?.required"
          class="form-text-alert"
        >
          Votre nom d'utilisateur est obligatoire.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre nom d'utilisateur doit contenir plus d'un caractère.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre nom d'utilisateur ne peut pas contenir plus de 50 caractères.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.pattern"
          class="form-text-alert"
        >
          Votre nom d'utilisateur est invalide.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Mot de passe</label>
      <input formControlName="password" placeholder="Votre mot de passe" type="password"/>

      <div
        *ngIf="registerForm.get('password')!.invalid && (registerForm.get('password')!.dirty || registerForm.get('password')!.touched)"
      >
        <small
          *ngIf="registerForm.get('password')?.errors?.required"
          class="form-text-alert"
        >
          Votre mot de passe est requis.
        </small>

        <small
          *ngIf="registerForm.get('password')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre mot de passe doit comporter au moins 4 caractères.
        </small>

        <small
          *ngIf="registerForm.get('password')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre mot de passe ne doit pas comporter plus de 50 caractères.
        </small>
      </div>

    </div>
    <div class="form-group">
      <label>Confirmation mot de passe</label>
      <input formControlName="confirmPassword" placeholder="Confirmer votre mot de passe" type="password"/>

      <div
        *ngIf="
              registerForm.get('confirmPassword')!.invalid &&
              (registerForm.get('confirmPassword')!.dirty || registerForm.get('confirmPassword')!.touched)
            "
      >
        <small
          *ngIf="registerForm.get('confirmPassword')?.errors?.required"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe est requise.
        </small>

        <small
          *ngIf="registerForm.get('confirmPassword')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe doit comporter au moins 4 caractères.
        </small>

        <small
          *ngIf="registerForm.get('confirmPassword')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe ne doit pas comporter plus de 50 caractères.
        </small>
      </div>
    </div>

    <button class="btn dark" type="submit">S'inscrire</button>

    <a [routerLink]="'/connexion'" class="little-text first">J'ai deja un compte, je veux me connecter</a>
  </form>
</div>
