<app-notifications></app-notifications>

<app-nav-bar-component></app-nav-bar-component>

<ng-container *ngIf="!(isLoadingListMode | async)">
  <ng-container *ngIf="(obsModes | async) as modes">
    <ng-container *ngIf="modes.length > 0">
      <router-outlet></router-outlet>
    </ng-container>

    <ng-container *ngIf="modes.length == 0">
      <h1>
        Victime de son succés<br/> Blinee est indisponible :(
      </h1>
    </ng-container>
  </ng-container>
</ng-container>

<app-footer-component></app-footer-component>
