import {Injectable} from '@angular/core';
import {CookieService} from "../../shared/services/util/cookie/cookie.service";

@Injectable({providedIn: 'root'})
export class StateStorageService {
  private previousUrlKey = 'previousUrl';
  private authenticationKey = 'jhi-authenticationToken';

  constructor(protected cookieService: CookieService) {
  }

  storeUrl(url: string): void {
    this.cookieService.getCookieService().setItem(this.previousUrlKey, JSON.stringify(url));
  }

  getUrl(): string | null {
    const previousUrl = this.cookieService.getCookieService().getItem(this.previousUrlKey);
    return previousUrl ? (JSON.parse(previousUrl) as string | null) : previousUrl;
  }

  clearUrl(): void {
    this.cookieService.getCookieService().removeItem(this.previousUrlKey);
  }

  storeAuthenticationToken(authenticationToken: string): void {
    authenticationToken = JSON.stringify(authenticationToken);
    this.clearAuthenticationToken();
    this.cookieService.getCookieService().setItem(this.authenticationKey, authenticationToken);
  }

  getAuthenticationToken(): string | null {
    const authenticationToken = this.cookieService.getCookieService().getItem(this.authenticationKey);
    return authenticationToken ? (JSON.parse(authenticationToken) as string | null) : authenticationToken;
  }

  clearAuthenticationToken(): void {
    this.cookieService.getCookieService().removeItem(this.authenticationKey);
  }
}
