import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {modeActionTypes} from './mode.actions';
import {IMode} from '../../model/mode/mode.model';

export interface ModeState extends EntityState<IMode> {
  isLoadingList: boolean;
  mode: IMode;
}

export const adapter: EntityAdapter<IMode> = createEntityAdapter<IMode>({
  selectId: mode => mode.id
});

export const initialState = adapter.getInitialState({
  isLoadingList: false,
});

export const modeReducer = createReducer(
  initialState,

  on(modeActionTypes.loadModes, (state) => ({
    ...state,
    isLoadingList: true,
  })),


  on(modeActionTypes.loadModesDone, (state, action) => {
    return adapter.setAll(
      action.modes.body,
      {
        ...state,
        mode: action.modes.body[0],
        isLoadingList: false,
      }
    );
  }),

  on(modeActionTypes.loadModesFail, (state) => ({
      ...state,
      isLoadingList: false,
    }
  )),

  on(modeActionTypes.selectMode, (state, action) => ({
      ...state,
      mode: action.mode,
    }
  )),
);

export const {selectAll} = adapter.getSelectors();
