import {createAction, props} from '@ngrx/store';
import {IMode} from '../../model/mode/mode.model';
import {Page} from '../../model/page.model';


export const loadModes = createAction(
  '[Modes List] Load modes via Service'
);

export const loadModesDone = createAction(
  '[Modes Effect] Modes Loaded Successfully',
  props<{ modes: Page<IMode> }>()
);

export const loadModesFail = createAction(
  '[Modes Effect] Modes Loaded failed'
);

export const selectMode = createAction(
  '[Mode] Select Mode ',
  props<{ mode: IMode }>()
);

export const modeActionTypes = {
  loadModes,
  loadModesDone,
  loadModesFail,
  selectMode,
};
