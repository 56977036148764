import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.module')
        .then((m) => m.HomeModule)
  },
  {
    path: 'jeu',
    loadChildren: () =>
      import('./features/game/game.module')
        .then((m) => m.GameModule)
  },
  {
    path: 'classement',
    loadChildren: () =>
      import('./features/ranking/ranking.module')
        .then((m) => m.RankingModule)
  },
  {
    path: 'mentions-legales',
    loadChildren: () =>
      import('./features/legalnotice/legal-notice.module')
        .then((m) => m.LegalNoticeModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  // useHash supports gitHub.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
