import {HttpParams} from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      for (const value of [].concat(req[key]).filter(v => v !== '')) {
        options = options.append(key, value);
      }
    });
  }

  return options;
};
