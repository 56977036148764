<div class="content">

  <div *ngIf="success" class="bloc-success">
    <div class="alert alert-success">
      Veuillez consulter vos emails pour réinitialiser votre mot de passe. Si vous ne le voyez pas, jetez un œil dans
      les spams. Et si ça prend 5 minutes... c'est que nos serveurs font une petite sieste !
    </div>
    <a [routerLink]="'/'" class="btn">Retour à l'accueil</a>
  </div>


  <form (ngSubmit)="requestReset()" *ngIf="!success" [formGroup]="resetRequestForm">
    <h1 class="logo">
      <img loading="lazy" alt="Logo" src="assets/images/logo.svg"/>
      <div class="title">BLINEE <img loading="lazy" src="/assets/images/beta.svg" alt="Beta blinee"></div>
    </h1>

    <h2>Réinitialiser son mot de passe</h2>

    <div *ngIf="success == false" class="alert alert-danger">
      Veuillez renseigner l'adresse email utilisée pour vous enregistrer
    </div>

    <div class="form-group">
      <label>Email</label>
      <input formControlName="email" placeholder="Votre adresse mail" type="text"/>
      <div
        *ngIf="resetRequestForm.get('email')!.invalid && (resetRequestForm.get('email')!.dirty || resetRequestForm.get('email')!.touched)">
        <small
          *ngIf="resetRequestForm.get('email')?.errors?.required"
          class="form-text-alert"
        >
          Votre email est requis.
        </small>

        <small
          *ngIf="resetRequestForm.get('email')?.errors?.invalid"
          class="form-text-alert"
        >
          Votre email n'est pas valide.
        </small>

        <small
          *ngIf="resetRequestForm.get('email')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre email doit comporter au moins 5 caractères.
        </small>

        <small
          *ngIf="resetRequestForm.get('email')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre email ne doit pas comporter plus de 50 caractères.
        </small>
      </div>
    </div>

    <button class="btn dark" type="submit">Réinitialiser le mot de passe</button>

    <a [routerLink]="'/connexion'" class="little-text first">Revenir sur Blinee</a>
  </form>
</div>
