import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import LoginComponent from "./login/login.component";
import {SigninComponent} from "./signin/signin.component";
import PasswordResetInitComponent from "./password-reset/init/password-reset-init.component";
import PasswordResetFinishComponent from "./password-reset/finish/password-reset-finish.component";
import {FinalisationComponent} from "./finalisation/finalisation.component";

const routes: Routes = [
  {
    path: 'connexion',
    component: LoginComponent,
  },
  {
    path: 'inscription',
    component: SigninComponent,
  },
  {
    path: 'finalisation',
    component: FinalisationComponent,
  },
  {
    path: 'mot-de-passe-oublie',
    component: PasswordResetInitComponent,
  },
  {
    path: 'modification-mot-de-passe-oublie',
    component: PasswordResetFinishComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
