import {ActionReducer, ActionReducerMap} from '@ngrx/store';


// tslint:disable-next-line:no-empty-interface
export interface AppState {

}

export const reducers: ActionReducerMap<AppState> = {};


// export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];


// make sure you export for AoT
export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export const metaReducers = [stateSetter];
