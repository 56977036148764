import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IMode} from '../../model/mode/mode.model';
import {TransferState} from '@angular/platform-browser';
import {STATE_LIST_MODE} from '../../transfert-state/state';
import {UtilsTransfertsState} from '../../../shared/services/util/utils-transferts-state';
import {modeActionTypes} from './mode.actions';
import {ModeService} from "../../../shared/services/mode.service";

@Injectable()
export class ModeEffects {

  loadModes$ = createEffect(() => {
      const actions = inject(Actions);
      return actions.pipe(
        ofType(modeActionTypes.loadModes),
        switchMap((action) => this.utilsTransfertsState.getStateOrQuery(STATE_LIST_MODE, this.modeService.query())),
        map(modes => modeActionTypes.loadModesDone({modes})),
        catchError((err, caught) => {
          this.store.dispatch(modeActionTypes.loadModesFail());
          return caught;
        })
      );
    }
  );

  constructor(
    private modeService: ModeService,
    private actions$: Actions,
    private router: Router,
    private state: TransferState,
    private utilsTransfertsState: UtilsTransfertsState,
    private store: Store<IMode>) {
  }
}
