import {Injectable} from '@angular/core';


@Injectable()
export class GameEffects {


  constructor() {
  }
}
