import {LOCALE_ID, makeStateKey, NgModule, TransferState} from '@angular/core';
import {BrowserModule, provideClientHydration} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {NavbarModule} from "./shared/component/navbar/navbar.module";
import {Store, StoreModule} from "@ngrx/store";
import {AppState, metaReducers, reducers} from "./core/store/app.state";
import {modeReducer} from "./core/store/mode/mode.reducers";
import {ModeEffects} from "./core/store/mode/mode.effects";
import {EffectsModule} from "@ngrx/effects";
import {HttpClientModule} from "@angular/common/http";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {DifficultyEffects} from "./core/store/difficulty/difficulty.effects";
import {difficultyReducer} from "./core/store/difficulty/difficulty.reducers";
import {navbarReducer} from "./core/store/navbar/navbar.reducers";
import {NavbarEffects} from "./core/store/navbar/navbar.effects";
import {gameReducer} from "./core/store/game/game.reducers";
import {GameEffects} from "./core/store/game/game.effects";
import {AuthRoutingModule} from "./features/auth/auth-routing.module";
import {httpInterceptorProviders} from "./core/interceptor";
import {NotificationsModule} from "./shared/component/notifications/notifications.module";
import {CookieModule} from "ngx-cookie";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from "@abacritt/angularx-social-login";
import {FooterModule} from "./shared/component/footer/footer.module";

export const NGRX_STATE = makeStateKey('NGRX_STATE');

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        SocialLoginModule,
        BrowserModule,
        NavbarModule,
        AppRoutingModule,
        AuthRoutingModule,
        HttpClientModule,
        CookieModule.withOptions(),
        StoreModule.forFeature('modes', modeReducer),
        StoreModule.forFeature('difficulties', difficultyReducer),
        StoreModule.forFeature('navbar', navbarReducer),
        StoreModule.forFeature('games', gameReducer),
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([
            ModeEffects,
            DifficultyEffects,
            NavbarEffects,
            GameEffects,
        ]),
        NotificationsModule,
        FooterModule
    ],
  providers: [
    provideClientHydration(),
    {
      provide: LOCALE_ID, useValue: 'fr-FR'
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '216426693077-06rbnvbkd11fdchrrakfuli6oh95pdn1.apps.googleusercontent.com'
            )
          }
        ],
        onError: (err: any) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(
    private readonly transferState: TransferState,
    private readonly store: Store<AppState>
  ) {
    const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer(): void {
    this.transferState.onSerialize(NGRX_STATE, () => {
      let state;
      this.store.subscribe({
        next: (saveState: any) => {
          state = saveState;
        },
        error: err => console.error(err)
      }).unsubscribe();

      return state;
    });
  }

  onBrowser(): void {
    const state = this.transferState.get<any>(NGRX_STATE, null);
    this.transferState.remove(NGRX_STATE);
    this.store.dispatch({type: 'SET_ROOT_STATE', payload: state});
  }
}
