import {createReducer, on} from '@ngrx/store';
import {navbarActionTypes} from "./navbar.actions";


export interface NavbarState {
  showTitle: boolean;
  showInfo: boolean;
  showLogin: boolean;
  showBackHome: boolean;
}

export const initialState = {
  showTitle: true,
  showInfo: true,
  showLogin: false,
  showBackHome: false
};

export const navbarReducer = createReducer(
  initialState,

  on(navbarActionTypes.showTitle, (state, action) => ({
      ...state,
      showTitle: action.show,
    }
  )),

  on(navbarActionTypes.showInfo, (state, action) => ({
      ...state,
      showInfo: action.show,
    }
  )),

  on(navbarActionTypes.showLogin, (state, action) => ({
      ...state,
      showLogin: action.show,
    }
  )),

  on(navbarActionTypes.showBackHome, (state, action) => ({
      ...state,
      showBackHome: action.show,
    }
  )),
);
