import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SsrContext {

  constructor(@Inject(PLATFORM_ID) private platformId: any) {

  }

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isClient(): boolean {
    return !this.isServer();
  }
}
