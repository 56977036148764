import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {AppState} from '../../core/store/app.state';
import {Store} from '@ngrx/store';
import {SsrContext} from './util/ssr-context.service';
import {DOCUMENT} from '@angular/common';

@Injectable({providedIn: 'root'})
export class SEOService {

  constructor(private title: Title,
              private meta: Meta,
              private store: Store<AppState>,
              private ssrContext: SsrContext,
              @Inject(DOCUMENT) private dom: any,
              private router: Router) {
  }

  updateSEO(title: string, desc: string, img?: string): void {
    const titleSEO = title.substring(0, 90);
    const descSEO = desc.substring(0, 159);

    this.meta.updateTag({name: 'description', content: descSEO});
    this.title.setTitle(titleSEO);

    this.meta.updateTag({name: 'og:type', content: 'website'});
    this.meta.updateTag({name: 'og:url', content: 'https://blinee.fr' + this.router.url});
    this.meta.updateTag({name: 'og:title', content: titleSEO});
    this.meta.updateTag({name: 'og:description', content: descSEO});
    this.meta.updateTag({name: 'og:image', content: img ?? 'https://blinee.fr/assets/images/logo.png'});

    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.updateTag({name: 'twitter:title', content: titleSEO});
    this.meta.updateTag({name: 'twitter:site', content: 'Blinee'});
    this.meta.updateTag({name: 'twitter:description', content: descSEO});
    this.meta.updateTag({name: 'twitter:url', content: 'https://blinee.fr' + this.router.url});
    this.meta.updateTag({name: 'twitter:image', content: img ?? 'https://blinee.fr/assets/images/logo.png'});

    this.updateCanonicalUrl('https://blinee.fr' + this.router.url);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
