import {createAction, props} from '@ngrx/store';


export const showTitle = createAction(
  '[Navbar] Show Title ',
  props<{ show: boolean }>()
);

export const showInfo = createAction(
  '[Navbar] Show Info ',
  props<{ show: boolean }>()
);

export const showLogin = createAction(
  '[Navbar] Show Login ',
  props<{ show: boolean }>()
);

export const showBackHome = createAction(
  '[Navbar] Show Back Home ',
  props<{ show: boolean }>()
);

export const navbarActionTypes = {
  showTitle,
  showInfo,
  showLogin,
  showBackHome,
};
