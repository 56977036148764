import {Component, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {loadModes} from "./core/store/mode/mode.actions";
import {AppState} from "./core/store/app.state";
import {Store} from "@ngrx/store";
import {getAllModes, isLoadingListMode} from "./core/store/mode/mode.selectors";
import {Observable} from "rxjs";
import {IMode} from "./core/model/mode/mode.model";
import {AccountService} from "./core/auth/account.service";
import {environment} from "../environments/environment";
import {SsrContext} from "./shared/services/util/ssr-context.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isLoadingListMode: Observable<boolean>;
  public obsModes: Observable<IMode[]>;
  trackingCode = environment.googleAnalyticsTrackingCode;

  constructor(private store: Store<AppState>,
              private ssrContext: SsrContext,
              private readonly renderer: Renderer2,
              private accountService: AccountService,
              @Inject(DOCUMENT) private dom: any,
              private readonly el: ElementRef) {
    this.store.dispatch(loadModes());
    this.isLoadingListMode = this.store.select(isLoadingListMode);
    this.obsModes = this.store.select(getAllModes);

    if (this.ssrContext.isClient()) {
      this.createAnalytics();
      this.accountService.identity().subscribe();
      this.clearCache();
    }
  }

  ngOnInit(): void {
    this.createCanonicalURL();
  }

  private clearCache(): void {
    if (caches) {
      caches.keys().then(cs => {
        if (cs.length > 0) {
          const versionSW = localStorage.getItem('version_service_worker');
          if (versionSW == null || versionSW !== environment.VERSION_SW) {
            cs.forEach(c => caches.delete(c));
          }
        }
        localStorage.setItem('version_service_worker', environment.VERSION_SW);
      });
    }
  }

  private createAnalytics(): void {
    if (this.trackingCode) {
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `//www.googletagmanager.com/gtag/js?id=${this.trackingCode}`;
      script.async = true;
      this.renderer.appendChild(this.el.nativeElement, script);

      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      const scriptBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', '${this.trackingCode}');
      `);
      this.renderer.appendChild(script2, scriptBody);
      this.renderer.appendChild(this.el.nativeElement, script2);
    }
  }

  private createCanonicalURL() {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.dom.URL);
  }
}
