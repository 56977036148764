import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared.module';


@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
  ],
  exports: [
    FooterComponent
  ],
  providers: [],
})
export class FooterModule {
}
