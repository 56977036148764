import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {StateStorageService} from './state-storage.service';
import {Login} from "../model/user/login.model";
import {environment} from "../../../environments/environment";

type JwtToken = {
  id_token: string;
};

@Injectable({providedIn: 'root'})
export class AuthServerProvider {
  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
  ) {
  }

  getToken(): string {
    return this.stateStorageService.getAuthenticationToken() ?? '';
  }

  login(credentials: Login): Observable<void> {
    return this.http
      .post<JwtToken>(`${environment.SERVER_API_URL}api/authenticate`, credentials)
      .pipe(map(response => this.authenticateSuccess(response)));
  }

  logout(): Observable<void> {
    return new Observable(observer => {
      this.stateStorageService.clearAuthenticationToken();
      observer.complete();
    });
  }

  private authenticateSuccess(response: JwtToken): void {
    this.stateStorageService.storeAuthenticationToken(response.id_token);
  }
}
