import {ModeState, selectAll} from './mode.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const modeFeatureSelector = createFeatureSelector<ModeState>('modes');

export const getAllModes = createSelector(
  modeFeatureSelector,
  selectAll
);

export const getCurrentMode = createSelector(
  modeFeatureSelector,
  state => state.mode
);

export const isLoadingListMode = createSelector(
  modeFeatureSelector,
  state => state.isLoadingList
);
