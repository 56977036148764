import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

import {Login} from '../../core/model/user/login.model';
import {AccountService} from "../../core/auth/account.service";
import {AuthServerProvider} from "../../core/auth/auth-jwt.service";
import {Account} from "../../core/auth/account.model";

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private accountService: AccountService, private authServerProvider: AuthServerProvider) {
  }

  login(credentials: Login): Observable<Account | null> {
    return this.authServerProvider.login(credentials).pipe(mergeMap(() => this.accountService.identity(true)));
  }

  logout(): void {
    this.authServerProvider.logout().subscribe({
      complete: () => {
        this.accountService.authenticate(null);
        location.reload();
      }
    });
  }
}
