<footer>
  <div class="left">
    <a class="link" [routerLink]="'mentions-legales'">
      Mentions légales
    </a>
  </div>

  <div class="right">
    <div class="join-us">
      Rejoins nous sur :
      <div class="social">
        <a class="tiktok" rel="noopener noreferrer" href="https://www.tiktok.com/@blinee_off" target="_blank">
          <img loading="lazy" src="/assets/images/social/tiktok.svg" alt="Lien blinee tiktok">
        </a>
        <a class="youtube" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC3pzpMZnwDLmfeI_kM61QTQ" target="_blank">
          <img loading="lazy" src="/assets/images/social/youtube.svg" alt="Lien blinee Youtube">
        </a>
        <a class="x" rel="noopener noreferrer" href="https://twitter.com/Blinee_officiel" target="_blank">
          <img loading="lazy" src="/assets/images/social/x.svg" alt="Lien blinee X">
        </a>
      </div>
    </div>

  </div>
</footer>
