import {createAction, props} from '@ngrx/store';
import {Difficulty} from "../../model/enumerations/difficulty.model";


export const selectDifficulty = createAction(
  '[Difficulty] Select Difficulty ',
  props<{ difficulty: Difficulty }>()
);

export const difficultyActionTypes = {
  selectDifficulty,
};
