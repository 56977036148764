import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../core/store/app.state";
import {
  getNavbarShowBackHome,
  getNavbarShowInfo,
  getNavbarShowLogin,
  getNavbarShowTitle
} from "../../../core/store/navbar/navbar.selectors";
import {Observable} from "rxjs";
import {AccountService} from "../../../core/auth/account.service";
import {LoginService} from "../../services/login.service";
import {Account} from "../../../core/auth/account.model";

@Component({
  selector: 'app-nav-bar-component',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  showInfoBlindTest = false;
  public showTitle: Observable<boolean>;
  public showInfo: Observable<boolean>;
  public showLogin: Observable<boolean>;
  public showBackHome: Observable<boolean>;
  public isLogin: boolean = false;
  public account: Account | null = null;

  constructor(private store: Store<AppState>,
              protected accountService: AccountService,
              protected loginService: LoginService) {
    this.showTitle = this.store.select(getNavbarShowTitle);
    this.showInfo = this.store.select(getNavbarShowInfo);
    this.showLogin = this.store.select(getNavbarShowLogin);
    this.showBackHome = this.store.select(getNavbarShowBackHome);
  }

  ngOnInit(): void {
    this.accountService.getAuthenticationState().subscribe(value => {
      this.isLogin = this.accountService.isAuthenticated();
      this.account = value;
    })
    this.isLogin = this.accountService.isAuthenticated();
  }

  logout(): void {
    this.loginService.logout();
  }
}
