import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';

import {PasswordResetFinishService} from '../../../../shared/services/password-reset-finish.service';
import {SharedModule} from "../../../../shared/component/shared.module";
import {showBackHome, showInfo, showLogin, showTitle} from "../../../../core/store/navbar/navbar.actions";
import {Store} from "@ngrx/store";
import {SsrContext} from "../../../../shared/services/util/ssr-context.service";
import {AccountService} from "../../../../core/auth/account.service";

@Component({
  selector: 'jhi-password-reset-finish',
  standalone: true,
  styleUrls: ['./password-reset-finish.component.scss', '../../auth.component.scss'],
  imports: [SharedModule, RouterModule, FormsModule, ReactiveFormsModule],
  templateUrl: './password-reset-finish.component.html',
})
export default class PasswordResetFinishComponent implements OnInit, AfterViewInit {
  @ViewChild('newPassword', {static: false})
  newPassword?: ElementRef;

  initialized = false;
  doNotMatch = false;
  error = false;
  success: boolean | null = null;
  key = '';

  passwordForm = new FormGroup({
    newPassword: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
    confirmPassword: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
  });

  constructor(private passwordResetFinishService: PasswordResetFinishService,
              private store: Store,
              private ssrContext: SsrContext,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute) {
    this.store.dispatch(showTitle({show: false}));
    this.store.dispatch(showInfo({show: false}));
    this.store.dispatch(showLogin({show: false}));
    this.store.dispatch(showBackHome({show: true}));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['key']) {
        this.key = params['key'];
      }
      this.initialized = true;
    });

    if(this.ssrContext.isClient()) {
      this.accountService.identity().subscribe(() => {
        if (this.accountService.isAuthenticated()) {
          this.router.navigate(['']);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.newPassword) {
      this.newPassword.nativeElement.focus();
    }
  }

  finishReset(): void {
    this.doNotMatch = false;
    this.error = false;

    const {newPassword, confirmPassword} = this.passwordForm.getRawValue();

    if (newPassword !== confirmPassword) {
      this.doNotMatch = true;
    } else {
      this.passwordResetFinishService.save(this.key, newPassword).subscribe({
        next: () => (this.success = true),
        error: () => (this.error = true),
      });
    }
  }
}
