<header>
  <div class="left">
    <img loading="lazy" *ngIf="(showTitle | async)" alt="Logo" src="assets/images/logo-body.svg"/>
    <div class="title-text">
      <a [routerLink]="'/'">
        <h1 *ngIf="(showTitle | async)">
          <div class="title">BLINEE <img src="/assets/images/beta.svg" alt="Beta blinee"></div>
        </h1>
      </a>
      <button (click)="showInfoBlindTest = true" *ngIf="(showInfo | async)" class="link">C'est quoi ?</button>
    </div>
  </div>

  <div class="right">
    <div *ngIf="(showBackHome | async)" class="return-home">
      <a [routerLink]="'/'" class="btn hide-mobile">Retour à l'accueil</a>
      <a [routerLink]="'/'" class="btn hide-desktop hide-tablet">Accueil</a>
    </div>
    <div *ngIf="(showLogin | async) && !isLogin" class="btn-login">
      <a [routerLink]="'/connexion'">
        <img loading="lazy" alt="Icone connexion" class="login" src="assets/images/login.svg"/>
      </a>
    </div>
    <div *ngIf="(showLogin | async) && isLogin" class="btn-login">
      <div class="click">
        <div appDropdown class="dropdown">
          <div class="link dropdown-title sort-by">
            <div class="icon-user">
              {{account?.login?.substring(0, 1)}}
            </div>
            <div class="dropdown-content dropdown-content-link">
              <div class="classic-content">
                <button (click)="logout()" class="link">Se déconnecter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div *ngIf="showInfoBlindTest" class="showInfoBlindTest">
  <div class="tooltip">
    <p>Avec l'intelligence artificielle il est possible aujourd'hui de recréer un mix d'une voix et d'une musique déjà
      existante.</p><br/>
    <p class="bold">Par exemple</p>
    <p class="example">"Johnny Hallyday qui chante le générique de Pokémon"</p>
    <p class="little">Oui ça existe</p><br/>
    <p class="bold">Le but</p>
    <p>A vous de retrouvez qui interprète le morceau et de retrouver le morceau d'origine.</p>
  </div>
  <div (click)="showInfoBlindTest = false" class="overlay"></div>
</div>
