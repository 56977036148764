import {createAction, props} from '@ngrx/store';
import {IGame} from "../../model/game/game.model";

export const changeGame = createAction(
  '[Game] Change game ',
  props<{ game: IGame | null }>()
);

export const changeIntervalLaunch = createAction(
  '[Game] Change interval launch ',
  props<{ intervalLaunch: any }>()
);

export const clearIntervalLaunch = createAction(
  '[Game] Clear interval launch',
);

export const validAnswer = createAction(
  '[Game] Valid answer',
);

export const clearGame = createAction(
  '[Game] Clear game',
);

export const gameActionTypes = {
  changeGame,
  clearGame,
  changeIntervalLaunch,
  clearIntervalLaunch,
  validAnswer,
};
