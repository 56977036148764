<div class="content">

  <div *ngIf="success" class="bloc-success">
    <div class="alert alert-success">
      <strong>Compte enregistré !</strong> Merci vous pouvez jouer et enregistrer vos scores
    </div>

    <a [routerLink]="'/'" class="btn">Retour à l'accueil</a>
  </div>


  <form (ngSubmit)="register()" *ngIf="!success" [formGroup]="registerForm">
    <h1 class="logo">
      <img loading="lazy" alt="Logo" src="assets/images/logo.svg"/>
      <div class="title">BLINEE <img loading="lazy" src="/assets/images/beta.svg" alt="Beta blinee"></div>
    </h1>

    <div *ngIf="error" class="alert alert-danger">
      <strong>Compte non créé !</strong> Merci d'essayer à nouveau plus tard.
    </div>

    <div *ngIf="errorUserExists" class="alert alert-danger">
      <strong>Ce compte utilisateur existe déjà !</strong> Veuillez en choisir un autre.
    </div>

    <div *ngIf="errorEmailExists" class="alert alert-danger">
      <strong>Un compte existe deja !</strong> Veuillez vous connecter
    </div>


    <div class="form-group">
      <label>Nom d'utilisateur</label>
      <input formControlName="login" placeholder="Votre nom d'utilisateur" type="text"/>

      <div
        *ngIf="registerForm.get('login')!.invalid && (registerForm.get('login')!.dirty || registerForm.get('login')!.touched)">
        <small
          *ngIf="registerForm.get('login')?.errors?.required"
          class="form-text-alert"
        >
          Votre nom d'utilisateur est obligatoire.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre nom d'utilisateur doit contenir plus d'un caractère.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre nom d'utilisateur ne peut pas contenir plus de 50 caractères.
        </small>

        <small
          *ngIf="registerForm.get('login')?.errors?.pattern"
          class="form-text-alert"
        >
          Votre nom d'utilisateur est invalide.
        </small>
      </div>
    </div>

    <button class="btn dark" type="submit">Créer son compte</button>
  </form>
</div>
