<div class="content">

  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <h1 class="logo">
      <img loading="lazy" alt="Logo" src="assets/images/logo.svg"/>
      <div class="title">BLINEE <img loading="lazy" src="/assets/images/beta.svg" alt="Beta blinee"></div>
    </h1>

    <div *ngIf="authenticationError" class="alert alert-danger">
      <strong>Erreur d'authentification !</strong> Veuillez vérifier vos identifiants de connexion.
    </div>

    <div class="form-group">
      <label>Nom d'utilisateur</label>
      <input #username formControlName="username" placeholder="Votre nom d'utilisateur" type="text"/>
    </div>
    <div class="form-group">
      <label>Mot de passe</label>
      <input formControlName="password" placeholder="Votre mot de passe" type="password"/>
    </div>
    <a [routerLink]="'/mot-de-passe-oublie'" class="little-text orange">Mot de passe oublié ?</a>

    <div class="row-btn">
      <button class="btn dark" type="submit">Se connecter</button>
      ou
      <asl-google-signin-button type='icon' size="large" shape="circle"></asl-google-signin-button>
    </div>

    <a [routerLink]="'/inscription'" class="little-text first">Je n'ai pas de compte, je veux m'inscrire</a>
    <a [routerLink]="'/'" class="little-text last">Je veux jouer en tant qu'invité</a>
  </form>
</div>
