import {createReducer, on} from '@ngrx/store';
import {gameActionTypes} from './game.actions';
import {IGame} from "../../model/game/game.model";

export interface GameState {
  intervalLaunch: any;
  game: IGame | null;
}

export const initialState: GameState = {
  intervalLaunch: null,
  game: null
};

function resetIntervalLaunch(state: GameState): null {
  if (state.intervalLaunch != null) {
    clearInterval(state.intervalLaunch);
  }
  return null;
}

export const gameReducer = createReducer(
  initialState,

  on(gameActionTypes.changeIntervalLaunch, (state: GameState, action) => ({
      ...state,
      intervalLaunch: action.intervalLaunch,
    }
  )),

  on(gameActionTypes.clearIntervalLaunch, (state: GameState) => ({
      ...state,
      intervalLaunch: resetIntervalLaunch(state),
    }
  )),

  on(gameActionTypes.changeGame, (state: GameState, action) => ({
      ...state,
      game: action.game,
    }
  )),

  on(gameActionTypes.clearGame, () => ({
      ...initialState
    }
  )),
);
