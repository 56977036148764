import {BehaviorSubject} from 'rxjs';

export class Notif {
  id: number;
  text: string;
  options: { timeout?: number } = {timeout: 5};
  level: 'success' | 'warning' | 'error' = 'success';
  paused: BehaviorSubject<boolean>;
  // @ts-ignore
  obs;

  constructor(params: any = {}) {
    this.id = new Date().getTime();
    this.paused = new BehaviorSubject<boolean>(false);

    // @ts-ignore
    if (this.level === 'error' && !this.text) {
      this.text = 'Une erreur est survenue';
    }

    this.text = params.text;
    this.options = params.options ? params.options : {};
    this.level = params.level;
  }
}
