import {NavbarState} from './navbar.reducers';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const navbarFeatureSelector = createFeatureSelector<NavbarState>('navbar');

export const getNavbarShowTitle = createSelector(
  navbarFeatureSelector,
  state => state.showTitle
);

export const getNavbarShowInfo = createSelector(
  navbarFeatureSelector,
  state => state.showInfo
);

export const getNavbarShowLogin = createSelector(
  navbarFeatureSelector,
  state => state.showLogin
);

export const getNavbarShowBackHome = createSelector(
  navbarFeatureSelector,
  state => state.showBackHome
);
