import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {getPausableTimer} from './notification-utils';
import {Notif} from "../../core/model/notif.model";


@Injectable({providedIn: 'root'})
export class NotificationsService {
  private notifications$ = new BehaviorSubject<Notif[]>([]);

  public getNotifications(): Observable<Notif[]> {
    return this.notifications$;
  }

  public notificationSuccess(text: string): void {
    const notification = new Notif({
      text,
      level: 'success',
      options: {timeout: 2}
    });
    this.addNotification(notification);
  }

  public notificationError(text: string): void {
    const notification = new Notif({
      text,
      level: 'error',
      options: {timeout: 2}
    });
    this.addNotification(notification);
  }

  public addNotification(notification: Notif): void {
    if (notification && notification.options && notification.options.timeout) {
      notification.obs = getPausableTimer(notification.options.timeout, notification.paused);
      notification.obs.completeTimer
        // @ts-ignore
        .pipe(tap(() => this.removeNotification(notification.id))).subscribe(
        () => {
        },
        (err: string) => console.error(err)
      );
    }
    this.next([...this.notifications$.getValue(), notification]);
  }

  public removeNotification(id: number): void {
    this.next(this.notifications$.getValue().filter(_ => _.id !== id));
  }

  private next(notifications: Notif[]): void {
    this.notifications$.next(notifications);
  }
}
