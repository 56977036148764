import {NgModule} from '@angular/core';
import {NavbarComponent} from './navbar.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared.module';
import {DropdownModule} from "../../../core/directive/dropdown/dropdown.module";


@NgModule({
  declarations: [
    NavbarComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    DropdownModule,
  ],
  exports: [
    NavbarComponent
  ],
  providers: [],
})
export class NavbarModule {
}
