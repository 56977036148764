import {Inject, Injectable, Optional} from '@angular/core';
import {REQUEST, RESPONSE} from '@nguniversal/express-engine/tokens';
import {Request, Response} from 'express';
import {ICookieService} from './cookie-client.inteface.service';

@Injectable({
  providedIn: 'root',
})
export class CookieServerService implements ICookieService {
  cookies: any = {};
  document: any = {cookie: ''};

  constructor(
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Optional() @Inject(RESPONSE) private res: Response
  ) {
    if (this.req !== null && this.req.headers.cookie) {
      this.req.headers.cookie.split('; ').forEach(cookie => {
        const indexOf = cookie.indexOf('=');
        this.cookies[cookie.substring(0, indexOf)] = cookie.substring(indexOf + 1);
      });
    }
  }

  getItem(name: string): string | null {
    if (name && typeof this.cookies[name] !== 'undefined') {
      return this.cookies[name];
    }
    return null;
  }

  setItem(name: string, value: string, expiry?: Date | string, path?: string): boolean {
    if (!name) {
      return false;
    }

    if (!path) {
      path = '/';
    }

    if (expiry) {
      if (!(expiry instanceof Date)) {
        expiry = new Date(expiry);
      }

      const dt = new Date();

      if (expiry.getTime() <= dt.getTime()) {
        this.removeItem(name, path);
      } else {
        this.cookies[name] = value;
        this.res.cookie(name, value, {
          expires: expiry,
          path,
          encode: String,
        });
      }
    } else {
      this.cookies[name] = value;
      this.res.cookie(name, value, {path, encode: String});
    }

    return true;
  }

  removeItem(name: string, path?: string): boolean {
    if (!name) {
      return false;
    }

    if (!path) {
      path = '/';
    }

    this.cookies[name] = null;
    const expiry = new Date('Thu, 01 Jan 1970 00:00:00 UTC');
    this.res.cookie(name, null, {expires: expiry, path, encode: String});

    return true;
  }
}
