import {Component, Input} from '@angular/core';
import {Notif} from "../../../../core/model/notif.model";
import {NotificationsService} from "../../../services/notifications.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  // @ts-ignore
  @Input() notification: Notif;

  constructor(private _notificationsService: NotificationsService) {
  }

  pause(): void {
    this.notification.paused.next(true);
  }

  unPause(): void {
    this.notification.paused.next(false);
  }

  remove(): void {
    this._notificationsService.removeNotification(this.notification.id);
  }

}
