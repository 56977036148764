import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Registration} from "../../core/model/user/register.model";
import {environment} from "../../../environments/environment";


@Injectable({providedIn: 'root'})
export class RegisterService {
  constructor(private http: HttpClient) {
  }

  save(registration: Registration): Observable<{}> {
    return this.http.post(`${environment.SERVER_API_URL}api/register`, registration);
  }
}
