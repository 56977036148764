import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {createRequestOption} from '../../core/request/request-util';
import {IMode} from '../../core/model/mode/mode.model';
import {environment} from "../../../environments/environment";

export type EntityArrayResponseType = HttpResponse<IMode[]>;

@Injectable({providedIn: 'root'})
export class ModeService {
  protected resourceUrl = `${environment.SERVER_API_URL}api/modes`;

  constructor(protected http: HttpClient) {
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const sort: string = 'id,asc'
    req = {
      sort
    }
    const options = createRequestOption(req);
    return this.http.get<IMode[]>(this.resourceUrl, {params: options, observe: 'response'});
  }
}
