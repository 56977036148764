import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';

import {LoginService} from '../../../shared/services/login.service';
import {AccountService} from "../../../core/auth/account.service";
import {showBackHome, showInfo, showLogin, showTitle} from "../../../core/store/navbar/navbar.actions";
import {AppState} from "../../../core/store/app.state";
import {Store} from "@ngrx/store";
import {NgIf} from "@angular/common";
import {SEOService} from "../../../shared/services/seo.service";
import {SsrContext} from "../../../shared/services/util/ssr-context.service";
import {GoogleSigninButtonModule, SocialAuthService} from "@abacritt/angularx-social-login";
import {Login} from "../../../core/model/user/login.model";
import {switchMap} from "rxjs/operators";
import {ProviderAuth} from "../../../core/model/user/provider-auth.enum";

@Component({
  selector: 'app-login',
  standalone: true,
  styleUrls: ['./login.component.scss', '../auth.component.scss'],
  imports: [FormsModule, ReactiveFormsModule, RouterModule, NgIf, GoogleSigninButtonModule],
  templateUrl: './login.component.html',
})
export default class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('username', {static: false})
  username!: ElementRef;

  authenticationError = false;
  private idToken = '';

  loginForm = new FormGroup({
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    password: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
  });

  constructor(private accountService: AccountService,
              private loginService: LoginService,
              private router: Router,
              private seoService: SEOService,
              private ssrContext: SsrContext,
              private authService: SocialAuthService,
              private store: Store<AppState>) {
    this.store.dispatch(showTitle({show: false}));
    this.store.dispatch(showInfo({show: false}));
    this.store.dispatch(showLogin({show: false}));
    this.store.dispatch(showBackHome({show: true}));
  }

  ngOnInit(): void {
    this.seoService.updateSEO('Connectez-vous à Blinee | Blind Test Musique IA', 'Connectez-vous à Blinee et accédez à une expérience de blind test unique avec des musiques générées par IA. Testez vos connaissances musicales maintenant !');

    this.authService.authState.pipe(switchMap((user) => {
      const connexion: Login = {
        idToken: user.idToken,
        provider: ProviderAuth.GOOGLE
      }

      this.idToken = user.idToken;


      return this.loginService.login(connexion)
    })).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          // There were no routing during login (eg from navigationToStoredUrl)
          this.router.navigate(['']);
        }
      },
      error: (err) => {
        if (err?.error?.detail === 'User not found with email' || err?.error?.detail === 'USER_DOESNT_EXIST') {
          localStorage.setItem('idToken', this.idToken);
          this.router.navigate(['/finalisation']);
        }
      },
    });

    if (this.ssrContext.isClient()) {
      this.accountService.identity().subscribe(() => {
        if (this.accountService.isAuthenticated()) {
          this.router.navigate(['']);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.username.nativeElement.focus();
  }

  login(): void {
    this.loginService.login(this.loginForm.getRawValue()).subscribe({
      next: () => {
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
          // There were no routing during login (eg from navigationToStoredUrl)
          this.router.navigate(['']);
        }
      },
      error: () => (this.authenticationError = true),
    });
  }
}
