import {createReducer, on} from '@ngrx/store';
import {difficultyActionTypes} from './difficulty.actions';
import {Difficulty} from "../../model/enumerations/difficulty.model";

let difficultyFromSession: string | null = null;
if (typeof sessionStorage !== 'undefined') {
  difficultyFromSession = sessionStorage.getItem('difficulty');
}

const validDifficulties = Object.values(Difficulty);
// @ts-ignore
const initialDifficulty = (validDifficulties.includes(difficultyFromSession)
  ? difficultyFromSession
  : Difficulty.EASY) as Difficulty;

export interface DifficultyState {
  difficulty: Difficulty;
}

export const initialState = {
  difficulty: initialDifficulty
};

export const difficultyReducer = createReducer(
  initialState,

  on(difficultyActionTypes.selectDifficulty, (state, action) => ({
      ...state,
      difficulty: action.difficulty,
    }
  )),
);
