<div class="content">

  <div *ngIf="success" class="bloc-success">
    <div class="alert alert-success">
      Votre mot de passe a été réinitialisé.
    </div>

    <a [routerLink]="'/'" class="btn">Connexion</a>
  </div>


  <form (ngSubmit)="finishReset()" *ngIf="!success" [formGroup]="passwordForm">
    <h1 class="logo">
      <img loading="lazy" alt="Logo" src="assets/images/logo.svg"/>
      <div class="title">BLINEE <img loading="lazy" src="/assets/images/beta.svg" alt="Beta blinee"></div>
    </h1>

    <h2>Réinitialisation du mot de passe</h2>

    <div *ngIf="key && success == false" class="alert alert-danger">
      Choisir un nouveau mot de passe
    </div>

    <div *ngIf="initialized && !key" class="alert alert-danger">
      La clef de réinitilisation est manquante
    </div>


    <div *ngIf="error" class="alert alert-danger">
      Votre mot de passe n'a pas pu être réinitialisé. La demande de réinitialisation n'est valable que 24 heures. Si
      vous avez demandé plusieurs fois, seule la dernière clé envoyée fonctionne.
    </div>

    <div *ngIf="doNotMatch" class="alert alert-danger">
      Le nouveau mot de passe et sa confirmation ne sont pas égaux !
    </div>

    <div class="form-group">
      <label>Mot de passe</label>
      <input formControlName="newPassword" placeholder="Votre mot de passe" type="password"/>

      <div
        *ngIf="passwordForm.get('newPassword')!.invalid && (passwordForm.get('newPassword')!.dirty || passwordForm.get('newPassword')!.touched)"
      >
        <small
          *ngIf="passwordForm.get('newPassword')?.errors?.required"
          class="form-text-alert"
        >
          Votre mot de passe est requis.
        </small>

        <small
          *ngIf="passwordForm.get('newPassword')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre mot de passe doit comporter au moins 4 caractères.
        </small>

        <small
          *ngIf="passwordForm.get('newPassword')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre mot de passe ne doit pas comporter plus de 50 caractères.
        </small>
      </div>

    </div>
    <div class="form-group">
      <label>Confirmation mot de passe</label>
      <input formControlName="confirmPassword" placeholder="Confirmer votre mot de passe" type="password"/>

      <div
        *ngIf="
              passwordForm.get('confirmPassword')!.invalid &&
              (passwordForm.get('confirmPassword')!.dirty || passwordForm.get('confirmPassword')!.touched)
            "
      >
        <small
          *ngIf="passwordForm.get('confirmPassword')?.errors?.required"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe est requise.
        </small>

        <small
          *ngIf="passwordForm.get('confirmPassword')?.errors?.minlength"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe doit comporter au moins 4 caractères.
        </small>

        <small
          *ngIf="passwordForm.get('confirmPassword')?.errors?.maxlength"
          class="form-text-alert"
        >
          Votre confirmation du mot de passe ne doit pas comporter plus de 50 caractères.
        </small>
      </div>
    </div>

    <button class="btn dark" type="submit">Réinitialiser le mot de passe</button>

    <a [routerLink]="'/connexion'" class="little-text first">Revenir sur Blinee</a>
  </form>
</div>
