import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

import {PasswordResetInitService} from '../../../../shared/services/password-reset-init.service';
import {SEOService} from "../../../../shared/services/seo.service";
import {SharedModule} from "../../../../shared/component/shared.module";
import {Router, RouterModule} from "@angular/router";
import {showBackHome, showInfo, showLogin, showTitle} from "../../../../core/store/navbar/navbar.actions";
import {Store} from "@ngrx/store";
import {SsrContext} from "../../../../shared/services/util/ssr-context.service";
import {AccountService} from "../../../../core/auth/account.service";

@Component({
  selector: 'app-password-reset-init',
  standalone: true,
  styleUrls: ['./password-reset-init.component.scss', '../../auth.component.scss'],
  templateUrl: './password-reset-init.component.html',
  imports: [SharedModule, RouterModule, FormsModule, ReactiveFormsModule],
})
export default class PasswordResetInitComponent implements AfterViewInit, OnInit {
  @ViewChild('email', {static: false})
  email?: ElementRef;

  success: boolean | null = null;
  resetRequestForm = this.fb.group({
    email: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]],
  });

  constructor(private passwordResetInitService: PasswordResetInitService,
              private fb: FormBuilder,
              private store: Store,
              private ssrContext: SsrContext,
              private accountService: AccountService,
              private router: Router,
              private seoService: SEOService
  ) {
    this.store.dispatch(showTitle({show: false}));
    this.store.dispatch(showInfo({show: false}));
    this.store.dispatch(showLogin({show: false}));
    this.store.dispatch(showBackHome({show: true}));
  }

  ngOnInit() {
    this.seoService.updateSEO('Blinee | Mot de Passe Oublié', 'Avez-vous oublié votre mot de passe ? Pas de problème. Suivez les étapes pour réinitialiser votre mot de passe et revenir à Blinee pour continuer à jouer.');

    if(this.ssrContext.isClient()) {
      this.accountService.identity().subscribe(() => {
        if (this.accountService.isAuthenticated()) {
          this.router.navigate(['']);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.email) {
      this.email.nativeElement.focus();
    }
  }

  requestReset(): void {
    this.passwordResetInitService.save(this.resetRequestForm.get(['email'])!.value).subscribe(() => (this.success = true));
  }
}
