import {Injectable} from '@angular/core';
import {SsrContext} from '../ssr-context.service';
import {CookieServerService} from './cookie-server.service';
import {CookieClientService} from './cookie-client.service';
import {ICookieService} from './cookie-client.inteface.service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor(private ssrContext: SsrContext,
              private cookieClientService: CookieClientService,
              private cookieServerService: CookieServerService,
  ) {
  }

  getCookieService(): ICookieService {
    if (this.ssrContext.isServer()) {
      return this.cookieServerService;
    } else {
      return this.cookieClientService;
    }
  }
}
