import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RegisterService} from "../../../shared/services/register.service";
import {HttpErrorResponse} from "@angular/common/http";
import {EMAIL_ALREADY_USED_TYPE, LOGIN_ALREADY_USED_TYPE} from "../../../core/config/error.constants";
import {SharedModule} from "../../../shared/component/shared.module";
import {Router, RouterModule} from "@angular/router";
import {LoginService} from "../../../shared/services/login.service";
import {Login} from "../../../core/model/user/login.model";
import {switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {showBackHome, showInfo, showLogin, showTitle} from "../../../core/store/navbar/navbar.actions";
import {Store} from "@ngrx/store";
import {AccountService} from "../../../core/auth/account.service";
import {SEOService} from "../../../shared/services/seo.service";
import {SsrContext} from "../../../shared/services/util/ssr-context.service";

@Component({
  selector: 'app-signin',
  standalone: true,
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss', '../auth.component.scss'],
  imports: [SharedModule, RouterModule, FormsModule, ReactiveFormsModule],
})
export class SigninComponent implements AfterViewInit, OnInit {
  @ViewChild('login', {static: false})
  login?: ElementRef;

  doNotMatch = false;
  error = false;
  errorEmailExists = false;
  errorUserExists = false;
  success = false;

  registerForm = new FormGroup({
    login: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$'),
      ],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
    confirmPassword: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(50)],
    }),
  });

  constructor(private registerService: RegisterService,
              private store: Store,
              private ssrContext: SsrContext,
              private accountService: AccountService,
              private router: Router,
              private seoService: SEOService,
              private loginService: LoginService) {
    this.store.dispatch(showTitle({show: false}));
    this.store.dispatch(showInfo({show: false}));
    this.store.dispatch(showLogin({show: false}));
    this.store.dispatch(showBackHome({show: true}));
  }

  ngAfterViewInit(): void {
    if (this.login) {
      this.login.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.seoService.updateSEO('Classement | Blinee Blind Test Musique IA', 'Consultez le classement de Blinee et découvrez comment vous vous situez par rapport aux autres joueurs. Relevez le défi et grimpez au classement !');

    if(this.ssrContext.isClient()) {
      this.accountService.identity().subscribe(() => {
        if (this.accountService.isAuthenticated()) {
          this.router.navigate(['']);
        }
      });
    }
  }

  register(): void {
    this.doNotMatch = false;
    this.error = false;
    this.errorEmailExists = false;
    this.errorUserExists = false;

    const {password, confirmPassword} = this.registerForm.getRawValue();
    if (password !== confirmPassword) {
      this.doNotMatch = true;
    } else {
      const {login, email} = this.registerForm.getRawValue();
      this.registerService
        .save({login, email, password})
        .pipe(switchMap((response: any) => {
          if (response != null) {
            this.processErrorString(response.detail);

            return of(false);
          }
          const connexion: Login = {
            username: login,
            password: password
          }
          return this.loginService.login(connexion);
        }))
        .subscribe({
          next: (value) => {
            if (value !== false) {
              this.success = true;
              this.store.dispatch(showBackHome({show: false}));
            }
          },
          error: response => this.processError(response)
        });
    }
  }

  private processError(response: HttpErrorResponse): void {
    if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
      this.errorUserExists = true;
    } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
      this.errorEmailExists = true;
    } else {
      this.error = true;
    }
  }

  private processErrorString(error: string): void {
    if (error === 'Login name already used!') {
      this.errorUserExists = true;
    } else if (error === 'Email is already in use!') {
      this.errorEmailExists = true;
    } else {
      this.error = true;
    }
  }
}
